$color-white-translucent: rgba(#fff, 0.9);
$color-black-translucent: rgba(#000, 0.7);
$color-black-translucent: rgba(#000, 0.9);
$color-black-translucenter: rgba(#000, 0.5);
$color-black-translucentest: rgba(#000, 0.3);
$color-grey-translucent: rgba(#ddd, 0.9);


$color-black: #000;
$color-white: #fff;

$color-grey-dark: #888;
$color-grey-darker: #666;
$color-grey-darkest: #444;
$color-grey: #aaa;
$color-grey-light: #ccc;
$color-grey-lighter: #ddd;
$color-grey-lightest: #eee;

$color-active: #43b9ff;

$grid-width: 150rem;
$gutter-vert: 8rem;
$gutter-horz: 6rem;