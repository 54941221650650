*, *::after, *::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}


html {
    font-size: 62.5%;
}

@import "variables";
@import "typography";
@import "utilities";
