@import "base";

.about {
    color: white;
    position: relative;

    &__section {
        margin: 2rem 0;
    }

    &__intro {
        margin: 4rem 0;
        width: 85%;
        margin: auto;

        p {
            margin: 2rem 0;
        }
    }

    &__contact {
        margin: 6rem auto;
        width: 75%;
        &--row {
            margin-top: 1.5rem;
        }

        h2 {
            margin: 2rem 0;
        }

        p {
            margin: 1rem 0;
            font-size: 4vw;
        }

        input {
            color: $color-grey-darkest;
        }
    }

    &__resume {
        position: relative;
        float: right;
        font-size: 2rem;
        text-transform: uppercase;

        a:active, a:link, a:visited {
            color: white;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            font-weight: 300;
        }
    }

    &__close {
        position: absolute;
        top: 4rem;
        right: 5rem;
        font-size: 2.5rem;
        cursor: pointer;

        &:hover {
            font-weight: 300;
        }
    }

    .contact-form {
        form {
            margin-top: 1rem;
            margin-bottom: 3rem;
            visibility: hidden;

            &.visible {
                visibility: visible !important;
            }
        }

        .form-toggle {
            cursor: pointer;
        }
        #sender-name {
            width: 100%;
        }
        #sender-email {
            width: 100%;
        }
        #subject {
            width: 100%;
        }
        #message {
            width: 100%;
            height: 10rem;
        }
    }
}

.body {
    background-color: $color-black-translucent;
    padding: 4rem;
    font-family: 'Roboto Mono', monospace;
    font-weight: 200;
    height: 75%;
    width: 100%;
    overflow: scroll;
    max-height: 95vh;
    border-radius: 5px;
    box-shadow: 0 1rem 3rem $color-black-translucentest;


    h2 {
        font-weight: 400;
        font-size: 4rem;
    }
}

@media (max-width: 640px) {
    .about {
        &__intro {
            p {
                font-size: 4vw;
            }
        }

        &__contact {
            p {
                font-size: 4vw;
            }
        }
    }

    .services {
        width: 85%;
        margin: 4rem auto;
    
        &__container {
            display: grid;
            margin: 4rem auto;
            text-align: center;
        }
    
        &__item {
            text-align: left;
            display: inline-flex;
            width: 30%;
            margin: 0 2rem;
    
            h3 {
                margin: 2rem;
                font-size: 2.5rem;
                align-self: center;
            }
            p {
                border-left: 1px solid white;
                font-size: 1.6rem;
                padding: 2rem;
            }
        }
    }

    .contact-form {
        form {
            width: 80%;
        }
    }
}

@media (min-width: 640px) {
    .about {
        &__intro {
            p {
                font-size: 3rem;
            }
        }

        &__contact {
            p {
                font-size: 3rem;
            }
        }
    }

    .services {
        width: 100%;
        margin: 4rem auto;
    
        &__container {
            display: grid;
            grid-template-columns: repeat(3, 1fr [col-start]);
            margin: 4rem auto;
            text-align: center;
        }
    
        &__item {
            text-align: left;
            grid-template-columns: repeat(2, 1fr [col-start]);
            display: grid;
            margin: 0 2rem;
            position: relative;
    
            h3 {
                margin: 2rem;
                font-size: 1.5vw;
                align-self: center;

            }
            
            p {
                border-left: 1px solid white;
                font-size: 1vw;
                padding-left: 2rem;
                height: 100%;
                padding-top: 25%;
            }
        }
    }

    .contact-form {
        form {
            width: 50%;
        }
    }
}
