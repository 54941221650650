.bg-vid {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;

    &__content {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.splash {
    height: 100vh;
    width: 100%;
    background-color: $color-black-translucent;
    position: absolute;
    overflow: hidden;
}

@media (max-width: 640px) {
    .title {
        position: absolute;
        width: 100%;
        height: 10%;
        text-align: center;
        top: 0;
        left: 0;
        margin: auto;
        font-size: 7vw;

        &__main {
            font-size: 7vw;
            letter-spacing: 1.4rem;
            transition: all .5s, opacity .2s;
        }

        &__sub{
            position: relative;
            letter-spacing: .3rem;
            left: 0;
            transition: all .6s, opacity .2s;
            font-size: 4vw;
        }
    }

    .menu {
        position: absolute;
        display: flexbox;
        width: 100%;
        height: 80%;
        top: 10%;
        
        &__container {
            height: 100%;
            position: relative;
        }

        &__list {
            display: grid;
            list-style: none;
            position: relative;
            text-align: center;
            height: 100%;
            padding: 2rem;
            align-items: center;
        }

        &__item {

            position: relative;
            cursor: pointer;
            border: 4px solid white;
            border-radius: 3px;
            height: 75%;
            width: 65%;
            justify-self: center;
            padding: 1.5rem;
            transition: transform .2s ease-out;   
            

            &:active {
                transform: scale(0.9, 0.9);
            }
        }

        &__link {
            color: black;
            display: grid;
            text-transform: uppercase;
            background-color: white;
            height: 100%;
            width: 100%;
            align-items: center;
            font-size: 5vw;
            font-weight: bold;
            border-radius: 3px;
            filter: drop-shadow(0px 0px 10px silver);
            transition: transform .2s ease-out;
            letter-spacing: 1vw;
        }
    }

}

@media (min-width: 640px) {
    .title {
        position: absolute;
        bottom: 0.5rem;
        right: 0;
        text-align: right;

        &__main {
            font-size: 3.2rem;
            letter-spacing: 1.4rem;
            transition: all .5s, opacity .2s;
        }

        &__sub{
            position: relative;
            letter-spacing: .4rem;
            left: -2%;
            transition: all .6s, opacity .2s;
            font-size: 1.8rem;
        }

    }

    .menu {
        display: inline-block;
        position: relative;
        top: 0%;
        height: 100%;
        width: 20rem
        
        &__checkbox {
            display: none;
        }
    
        &__btn {
            background-color: none;
            height: 7rem;
            width: 7rem;
            border-radius: 50%;
            position: fixed;
            top: 2rem;
            left: 2rem;
            z-index: 1002;
            box-shadow: 0 1rem 3rem rgba($color-black, .4);
            cursor: pointer;
        }
    
        &__container {
            position: relative;
            background-color: $color-black-translucenter;
            border-radius: 1rem;
            height: 100%;
            left: -15rem;
            opacity: 0;
            transition: left .3s ease-in, opacity .2s ease-in;
            overflow: hidden;
            padding: 1.5rem;
            z-index: 1000;
        }
    
        &__icon {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
    
            &, &::before, &::after {
                width: 3rem;
                height: 2px;
                background-color: $color-white;
                display: inline-block;
                transition: top .1s;
                transition: transform .2s;
                transform: background-color .2s;
            }
    
            &::before, &::after {
                content: "";
                position: absolute;
                left: 0;
            }
    
            &::before {
                top: -.8rem;
            }
    
            &::after {
                top: .8rem;
            }
        }
    
        &__btn:hover &__icon::before {
            top: -1rem;
        }
    
        &__btn:hover &__icon::after {
            top: 1rem;
        }
    
        &__checkbox:checked + &__btn &__icon {
            background-color: transparent;
        }
        &__checkbox:checked + &__btn &__icon::before {
            top: 0;
            transform: rotate(135deg);
        }
        &__checkbox:checked + &__btn &__icon::after {
            top: 0;
            transform: rotate(-135deg);
        }
    
        &__checkbox:checked ~ &__container {
            opacity: 1;
            left: 0;
            transition: left .2s ease-out, opacity .3s ease-in;
        }
    
        &__list {
            list-style: none;
            position: relative;
            top: 44.3%;
        }
    
        &__item {
            background-image: linear-gradient(90deg, transparent 0%, transparent 50%, $color-white-translucent 50%, $color-white-translucent 55%, transparent 55%, transparent 57%, $color-white-translucent 57%);
            background-size: 200%;
            transition: all .3s;
            padding: 1rem 0rem;
    
            &:hover {
                transform: translateX(2rem);
                background-position: 108%;
            }
    
            &:not(:last-child) {
                margin-bottom: 5rem;
            }
        }
    
        &__link {
            color: white;
            text-transform: uppercase;
            font-size: 2.8rem;
            padding: 2rem 3rem;
            text-decoration: none;
            cursor: pointer;
        }
    
        &__item:hover > &__link{
            color: black;
        }
    
        &__input {
            display: none !important;
        }
    }
}

.title {
    color: white;
    display: flexbox;
    text-transform: uppercase;
    transition: .4s, opacity .2s;
    cursor: pointer;

    &.hide {
        opacity: 0;
    }
    

    &:hover {
        transform: scale(1.05) translateX(-2rem) translateY(-.6rem);
    }
    &:hover &__main {
        letter-spacing: 1.5rem;
    }
    &:hover &__sub {
        letter-spacing: .5rem;
    }
}

.subpage {
    position: absolute;
    margin: 4rem 4rem;
    border-radius: 400rem;
    box-shadow: 0 2rem 4rem $color-black-translucenter;

    &__body {
        background-color: $color-grey-translucent;
        height: 75vh;
    }
}

#subpage-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }