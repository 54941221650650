.u-page-padding { padding: 3rem; }
.u-page-padding-m { padding: 5rem; }
.u-page-padding-l { padding: 8rem; }



.u-half-width-center {
    margin-left: 25%;
    width: 50%;
}

.u-center-text { text-align: center !important; }

.u-margin-bottom-s { margin-bottom: 1.5rem !important; }
.u-margin-bottom-m { margin-bottom: 4rem !important; }
.u-margin-bottom-l { margin-bottom: 8rem !important; }
.u-margin-bottom-xl { margin-bottom: 10rem !important; }

.u-margin-top-s { margin-top: 1.5rem !important; }
.u-margin-top-m { margin-top: 4rem !important; }
.u-margin-top-l { margin-top: 8rem !important; }
.u-margin-top-xl { margin-top: 10rem !important; }
